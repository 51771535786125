









































import vue from 'vue';
import { Component } from 'vue-property-decorator';
import RGBClass from '@/helper/ColorChange';
import { RGB, GetRGBList } from '@/helper/Colors';

@Component({
    components: {
        Background: () => import('@/components/Background.vue'),
    },
})
export default class FirstLook extends vue {
    scrollVariable: number = 0;
    titleName: string = 'ShrikantPatel';
    char = -1;
    colorChar: number = 0;
    timer: any;
    titleColorArray: any[] = [];
    animTime: number = 0;
    colorList: RGB[] = GetRGBList;
    colorIndex: number = 0;

    iAmList: string[] = [
        'Web Designer',
        'Software Developer',
        'Algorithm Enthusiast',
        'rookie Content Creator',
        'Former Soccer Player',
        'Painter',
        'Cook',
        'Star Wars',
        'Marvels',
        'Game of Thrones',
        'Gym Enthusiastic',
    ];

    created() {
        let index = 0;
        for (let letter of this.titleName) {
            let color: any = new RGBClass();
            let limit: number = index * 100;
            for (let i = 0; i < limit; i++) color.colorChange();
            this.titleColorArray.push({
                letter,
                index,
                color,
            });
            index++;
        }
    }

    //do not make it array function
    mounted() {
        this.timer = setInterval(this.onTick, 100);
        this.onTick();
        let text = document.querySelector('.sub-title-container');
        for (let iAm in this.iAmList) {
            let span: any = text?.querySelectorAll('.sub-titles')[iAm];
            let colorIndx = Number(iAm) % this.colorList.length;
            span.style.setProperty('--lineColor', `#${this.colorList[colorIndx]}`);
        }
    }

    //do not make it array function
    onTick() {
        let text = document.querySelector('.anim-title');
        let span: any = text?.querySelectorAll('.title-letter')[this.char];
        span?.classList.add('fade');
        this.char++;
        if (this.char == this.titleName.length) {
            clearInterval(this.timer);
            this.timer = null;
            this.char = 0;
            this.timer = setInterval(this.slowColorChange, 100);
            return;
        }
    }

    //do not make it array function
    slowColorChange() {
        let text: any = document.querySelector('.anim-title');
        let span: any = text.querySelectorAll('.title-letter')[this.char];
        if (this.colorIndex % 2 === 0) {
            span.style.color = `#${this.colorList[this.colorIndex / 2]}`;
        } else {
            if (Math.floor(this.colorIndex / 2) % 2)
                span.style.backgroundImage = `linear-gradient(#${this.colorList[Math.floor(this.colorIndex / 2)]}, #${
                    this.colorList[Math.floor(this.colorIndex / 2 + 1)]
                })`;
            else
                span.style.backgroundImage = `linear-gradient(#fff, #${
                    this.colorList[Math.floor(this.colorIndex / 2)]
                })`;
            span.style.webkitBackgroundClip = 'text';
            span.style.color = 'transparent';
        }
        // if (++this.animTime === 1000) {
        //     clearInterval(this.timer);
        //     this.timer = null;
        //     return;
        // }
        if (++this.char > this.titleName.length - 1) {
            this.colorIndex = ++this.colorIndex % (this.colorList.length * 2);
            this.char = 0;
        }
    }
}
